<template>
    <div class="page">
        <div class="container">
            <div class="header">
                <el-image :src="require('@/assets/LOGO.svg')" @click="toHome" style="cursor: pointer;width: 155px; height: 21px;"></el-image>
                <div class="header__content">
                    <a href="mailto:exam@holowits.com" class="contact">Contact Us</a>
                </div>
            </div>
            <div class="container__body" style="padding: 30px 90px 40px;">
                <template v-if="!showSuccess">
                    <h1 class="login_title">{{$i18n.t('register.register')}}</h1>
                    <el-form :model="loginForm" class="login_form" :rules="rules" ref="registerForm">
                        <el-form-item prop="email">
                            <el-input :placeholder="$t('register.email')" v-model="loginForm.email"/>
                        </el-form-item>
                        <el-form-item prop="companyName">
                            <el-input :placeholder="$i18n.t('register.companyName')" v-model="loginForm.companyName"/>
                            <!-- <el-select :placeholder="$i18n.t('register.companyName')" v-model="loginForm.companyName" clearable filterable style="width: 100%;">
                                <el-option v-for="(item, index) in companyArr" :key="index" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                            <div style="font-size: 12px; line-height: 1; padding-top: 4px; color: #d2333c;">Not found your company? Please register your company as HOLOWITS tier 2 partner first at https://holowits.com/reseller</div> -->
                        </el-form-item>
                        <el-form-item prop="name">
                            <el-input :placeholder="$i18n.t('register.name')" v-model="loginForm.name"/>
                        </el-form-item>
                        <el-form-item prop="country">
                            <el-row>
                                <!-- <el-col :span="8">
                                    <el-select placeholder="Area" v-model="continent" clearable style="width: 100%;" @change="selectedContinent">
                                        <el-option v-for="(item, index) in continents" :key="index" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </el-col> -->
                                <el-col :span="24">
                                    <el-select :placeholder="$i18n.t('register.country')" v-model="loginForm.country" clearable filterable style="width: 100%;" @change="filterCompany">
                                        <el-option v-for="(item, index) in countryArr" :key="index" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item prop="description">
                            <el-input v-model="loginForm.description" :placeholder="$i18n.t('register.description')"/>
                        </el-form-item>
                        <el-form-item prop="code">
                            <div class="flex-content">
                                <el-input :placeholder="$i18n.t('register.code')" v-model="loginForm.code"/>
                                <div class="verification-code-image" @click="getCodeImage">
                                <img :src="imageCode" alt="">
                            </div>
                        </div>
                        </el-form-item>
                    </el-form>
                    <div class="flex-btn-group">
                        <el-button type="primary" class="submit_btn" plain @click="clear">{{$i18n.t('register.cancel')}}</el-button>
                        <el-button type="primary" class="submit_btn" @click="submit">{{$i18n.t('register.submit')}}</el-button>
                    </div>
                </template>
                <template v-if="showSuccess">
                    <div class="success-panel">
                        <img class="success-panel-icon" src="../../assets/image/login/icon_success.png" alt="success">
                        <div class="success-panel-title">{{$i18n.t('register.successPanelTitle')}}</div>
                        <p class="success-panel-text">{{$i18n.t('register.successPanelText')}}</p>
                        <el-button type="primary" @click="back">{{$i18n.t('register.back')}}</el-button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {sendEmail, getCaptcha, applicant, codeCheck} from '@/api/login';
import {randomNum,initDicts,initQueryParams} from '@/utils/commons';
import countrys from '@/utils/country.json'

export default {
    name: 'WebsiteVueRegister',

    data() {
        //自定义的邮箱验证规则
        let checkEmail = (rule, value, callback) => {
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regEmail.test(value)) {
            return callback();
        }
            callback(new Error(this.$t('login.rulesEmail')));
        };
        return {
            showSuccess: false,
            loginForm: {
                email: '',
                companyName: '',
                name: '',
                country: '',
                description: '',
                code: ''
            },
            imageCode: '',
            rules: {
                email: [{required: true, message: this.$i18n.t('register.rules.email'), trigger: 'blur'}, {
                    validator: checkEmail,
                    trigger: 'blur'
                }],
                companyName: [{required: true, message: this.$i18n.t('register.rules.companyName'), trigger: 'blur'}, 
                // {
                //     validator: (rule, value, callback) => {
                //         if (/^[A-Z\s.-]+$/.test(value)) {
                //             return callback()
                //         }
                        
                //         callback(new Error(this.$t('register.rules.companyName')));
                //     }, 
                //     trigger: 'change'
                // }
                ],
                name: [{required: true, message: this.$i18n.t('register.rules.name'), trigger: 'blur'}],
                country: [{required: true, message: this.$i18n.t('register.rules.country'), trigger: 'blur'}],
                description: [{required: true, message: this.$i18n.t('register.rules.description'), trigger: 'blur'}],
                code: [{required: true, message: this.$i18n.t('register.rules.code'), trigger: 'blur'}],
            },
            key: randomNum(24, 16),
            continent: '',
            // continents: [],
            countryArr: [],
            companyArr: [],
            dicts: {
                companyName: [],
            },
            companyNames: []
        };
    },

    created() {
        this.countryArr = countrys.country.map(item => {
            return {label: item, value: item}
        })
    },

    async mounted() {
        
        this.getCodeImage()
        await initDicts(['companyName'], this.dicts);
        for (let item of this.dicts.companyName) {
            // console.log(item.code)
            this.companyNames.push(JSON.parse(item.code))
        }
    },

    methods: {
        // selectedContinent(value) {
        //     this.loginForm.country = ''
        //     this.countryArr = countrys[value].map(item => {
        //         return {label: item, value: item}
        //     })
        // },
        filterCompany(item) {
            let companyArr = this.companyNames.filter(i => i.country === item);
            this.companyArr = companyArr;
        },
        async submit() {
            const result = await this.check()
            if (result) {
                const {data: {data, isSuccess}} = await codeCheck(this.key, this.loginForm.code)
                if (isSuccess && data) {
                    let obj = JSON.parse(JSON.stringify(this.loginForm))
                    delete obj.code
                    const response = await applicant({
                        ...obj,
                        account: obj.email
                    })
                    if (response.data.isSuccess) {
                        this.showSuccess = true
                    }
                } else {
                    this.$message.error('Verification code error')
                }
            }
        },
        check() {
            return new Promise((resolve, reject) => {
                this.$refs['registerForm'].validate((valid) => {
                    resolve(valid)
                }).catch(e => {
                    reject(false)
                })
            })
        },
        clear() {
            this.loginForm = {
                email: '',
                companyName: '',
                name: '',
                country: '',
                code: ''
            }
            this.$router.push('/login');
        },
        back() {
            this.clear()
            this.showSuccess = false
        },
        toHome() {
            this.$router.replace({name: 'home'});
        },
        getCodeImage() {
            getCaptcha(this.key).then(response => {
                const res = response.data;
                return ('data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '')) );
            }).then(res => {
                this.imageCode = res;
            }).catch(e => {
            if (e.toString().indexOf('429') !== -1) {
                this.$message({
                    message: this.$t('tipInfo.tooManyRequest'),
                    type: 'error',
                });
            } else {
                this.$message({
                    message: this.$t('tipInfo.getCodeImageFailed'),
                    type: 'error',
                });
            }
            });
        }
    },
};
</script>

<style scoped lang="less">
  @import "./login.less";
</style>
<style scoped lang="less">
  .container {
    height: 100vh;
  }
  .cus-popper {
    transform: translateY(-8px);
  }

</style>